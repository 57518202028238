import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import Alert from '@mui/material/Alert';
import img_login from '../Resources/login_pic.png';

export default function Login(props) {
  const [error, set_error] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    Axios.post(`${props.server_url}/login`, {
      courriel: data.get('email'),
      password: data.get('password')
    })
      .then((response) => {
        if (response.status === 200) {
          const token = response.data.token; // Get the token from the response
          localStorage.setItem('token', token); // Store the token in local storage
          getLogin(); // Call getLogin to check authentication status
        }
      })
      .catch((error) => {
        set_error(true);
        console.log(error);
      });
  };

const getLogin = () => {
  const token = localStorage.getItem('token'); // Get the token from local storage

  if (token) {
    Axios.get(`${props.server_url}/login`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    })
      .then((response) => {
        if (response && response.status === 200) {
          window.location.href = "/";
          return;
        }

        if (response && response.status === 202) {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    console.log("No token found, user is not authenticated.");
  }
};

  return (
    <Grid container component="main" sx={{ height: '100vh', backgroundImage: `url(${img_login})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: 400,
          }}
        >
          
          <Typography component="h1" variant="h5" sx={{ textAlign: 'center', fontFamily: 'BasteleurBold' }}>
            Portail des artistes
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Courriel"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {error && <Alert severity="error" variant='filled'>Mauvais courriel ou mot de passe.</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: '#e16a5e',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '20px',
                textTransform: 'none',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                '&:hover': {
                  backgroundColor: '#d45a51',
                },
              }}
            >
              Connection
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
