import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Grid, Box, Typography, Container, Divider } from '@mui/material';
import { Carousel } from 'primereact/carousel';
import AcceuilImage from '../Resources/Logo_site.png';
import AcceuilWhoAreWe from '../Resources/Bisous.png';

export default function Accueil(props) {
  const carouselItemStyle = {
    height: 'auto',
    maxWidth: '100%',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const [mesImages, setMesImages] = useState([]);

  const handleNewImages = async (newPhotos) => {
    setMesImages((prevImages) => [...new Set([...prevImages, ...newPhotos])]);
  };

  const caroussel_template = (item) => {
    return (
      <div style={carouselItemStyle} className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <img src={item} alt="Artwork" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL_API}/getImagesAdmin`).then(async (response) => {
     

        const mes_images = Object.values(response.data.message.map((images) => {
          const clean_photos = images.images_link.split(';')[0].trim();
          return clean_photos;
        })
      );
      await handleNewImages(mes_images);
    });
  }, []);

  return (
      <Container maxWidth="xl" sx={{ padding: '2rem 0', borderRadius: '16px', // Rounded corners
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Soft shadow
        backdropFilter: 'blur(3px)', // Frosted glass effect
        WebkitBackdropFilter: 'blur(3px)', // Frosted glass effect for Safari
        border: '1px solid rgba(255, 255, 255, 0.3)', marginTop: '5rem' }}>
      <Grid container spacing={10}>
        {/* Image on the left */}
        <Grid item xs={12} md={4} style={{ order: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={AcceuilImage}
            alt="Shop image of a crescent moon with a big ass."
            style={{ width: '90%', height: '36rem', borderRadius: '8px' }} 
          />
        </Grid>
  
        {/* Text in the center */}
        <Grid item xs={12} md={4} style={{ order: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img 
          src={AcceuilWhoAreWe}
          alt="Who are we image"
          style={{ width: '30rem', height: '10rem'}}
          />
    
          {/* <Typography
            variant="h4"
            component="h4"
            style={{ fontFamily: 'BasteleurBold', marginBottom: '1rem', color: '#2f4f4f', textAlign: 'center' }}
          >
            Qui sommes-nous?
          </Typography> */}
          <Divider style={{ width: '100%', marginBottom: '1rem' }} />
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="body1"
              align="center"
              style={{ fontFamily: 'AvenirRoman', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '1rem', textAlign: 'justify' }}
            >
              La coopérative La Boîte à Lune est l’unique studio de tatouage à identité queer dans la ville de Québec et
              ses environs immédiats.
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{ fontFamily: 'AvenirRoman', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '1rem', textAlign: 'justify' }}
            >
              Ses cinq membres se sont associé.es pour créer un lieu de travail à leur image et à celle des clients, pour
              s’éloigner du mode de fonctionnement basé sur le pouvoir et le profit, pour encourager un modèle d’affaires
              et une culture du tatouage équitable et éthique, et pour promouvoir une diversité de techniques et de
              styles de tatouages.
            </Typography>
          </Box>
        </Grid>
  
        {/* Iframe on the right */}
        <Grid item xs={12} md={4} style={{ order: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box style={{ width: '100%', paddingTop: '100%', position: 'relative', overflow: 'hidden', borderRadius: '50%' }}>
              <iframe
                style={{ border: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '50%' }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2769.308224327468!2d-71.23046308448276!3d46.81243797913783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb897efd7f7e233%3A0xd940ea55f9feceb3!2sLa%20Bo%C3%AEte%20%C3%A0%20Lune!5e0!3m2!1sen!2sca!4v1662490065739!5m2!1sen!2sca"
              ></iframe>
            </Box>
          </Grid>
  
        {/* Carousel below */}
        <Grid item xs={12} style={{ order: 4 }}>
          <Divider />
          <Box mt={2}>
            <Carousel
              value={mesImages}
              itemTemplate={caroussel_template}
              numVisible={2}
              numScroll={2}
              responsiveOptions={responsiveOptions}
              autoplayInterval={4000}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
