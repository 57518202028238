import React from 'react'
import { Instagram, Facebook, Language, YouTube } from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import { Avatar, Badge } from '@mui/material';
import Chip from '@mui/material/Chip';



export default function SocialMediaBadge(props) {

    const type_social_media = {
        Instagram: {
            icon: <Instagram />,
            color: '#9c94d0'
          },
          Facebook: {
            icon: <Facebook />,
            color: '#6479bd'
          },
          Weblink: {
            icon: <Language />,
            color: '#007e6d'
          },
          YouTube: {
            icon: <YouTube/>,
            color: '#b4c9c8'
          },
          TikTok: {
            icon: <i className='pi pi-tiktok'></i>,
            color: '#64bdbd'
          },
          Autre: {
            icon: <PublicIcon />,
            color: '#0a433d'
          }
    
        };

  return (
<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
  {props.socialMedia.map((media, index) => {
    const mediaInfo = type_social_media[media.type_media] || {}; 

    return (
      <Chip 
        key={index}
        avatar={<Badge
          badgeContent={mediaInfo.icon}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: mediaInfo.color, // Color of the badge
              color: '#fff', // Text color inside the badge
              fontFamily: 'AvenirRoman',
            }
          }}
        />}
        label={media.type_media}
        clickable
        component='a'
        href={media.url_media}
        target='_blank'
        style={{
          backgroundColor: mediaInfo.color,
          color: '#fff', // Sets the text color of the chip
          borderRadius: '5px',
          padding: '10px'
        }}
      />
    );
  })}
</div>
  )
}
