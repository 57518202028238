import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  CardContent,
  Grid,
} from '@mui/material';
import Axios from 'axios';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Compressor from 'compressorjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputTextarea } from "primereact/inputtextarea";

export default function FormDemandes(props) {
  const [artists, setArtists] = useState([]);
  const [artisteChoisi, setArtisteChoisi] = useState({});
  const [nouvelleImage, setNouvelleImage] = useState([]);
  const [pronom, setPronom] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [courriel, setCourriel] = useState('');
  const [telephone, setTelephone] = useState('');
  const [description, setDescription] = useState('');

  const refreshPage = () => {
    window.location.reload();
  };

  const handleFileUpload = (event) => {
    const fileArray = Array.from(event.target.files).map((file) => {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = () =>
              resolve({
                dataUrl: reader.result,
                file: result,
              });
          },
        });
      });
    });

    Promise.all(fileArray).then((fileUrls) => {
      setNouvelleImage((prevImages) => [...prevImages, ...fileUrls]);
    });
  };

  const compressAndUpload = () => {
    nouvelleImage.forEach((image) => {
      new Compressor(image.file, {
        quality: 0.6,
        success(result) {
          const formData = new FormData();
          formData.append('image', result, result.name);
          Axios.put(`${process.env.REACT_APP_URL_API}/ImagesUploader`, formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            if (response.status === 200) {
              refreshPage();
            }
          });
        },
        error(err) {
          console.log(err.message);
        }
      });
    });
  };

  const handleDeleteImage = (index) => {
    setNouvelleImage((currentImages) => currentImages.filter((img, idx) => idx !== index));
  };

  const RetourneMesArtiste = async () => {
    Axios.get(`${process.env.REACT_APP_URL_API}/FetchArtisteNotAdmin`)
      .then((response) => {
        setArtists(response.data);
        setArtists(previous => [...previous, { id: 'home', name: 'Envoyer à tous les artistes' }]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChoosenArtist = (event) => {
    setArtisteChoisi(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();


    Axios.post(`${process.env.REACT_APP_URL_API}/DemandeProjet`, {
      pronom: pronom,
      nom: nom,
      prenom: prenom,
      courriel: courriel,
      telephone: telephone,
      description: description,
      artisteId: artisteChoisi,
      images: nouvelleImage
    }).then((response) => {
      if (response.status === 200) {
        const data = {
          isValid: true
        }

        const queryData = new URLSearchParams(data).toString()
        window.location.href = `/ConfirmationDemandeProjet?${queryData}`;
      }

      if(response.status === 406) {
        const data = {
          isValid: false
        }

        const queryData = new URLSearchParams(data).toString()
        window.location.href = `/ConfirmationDemandeProjet?${queryData}`;
      }

      if(response.status === 500) {
        const data = {
          isValid: false
        }

        const queryData = new URLSearchParams(data).toString()
        window.location.href = `/ConfirmationDemandeProjet?${queryData}`;
      }

      
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    RetourneMesArtiste();
  }, []);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        maxWidth: 800,
        mx: 'auto',
        p: 4,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        marginTop: '5rem',
        backdropFilter: 'blur(15px)', 
        WebkitBackdropFilter: 'blur(15px)', 
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontFamily: 'BasteleurBold', color: '#2f4f4f' }}>Envoyez-nous une demande de projet</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField required label="Prénom" variant="outlined" fullWidth style={{ fontFamily: 'AvenirRoman' }} onChange={(e) => setPrenom(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField required label="Nom" variant="outlined" fullWidth style={{ fontFamily: 'AvenirRoman' }} onChange={(e) => setNom(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField required label="Courriel" type="email" variant="outlined" fullWidth style={{ fontFamily: 'AvenirRoman' }} onChange={(e) => setCourriel(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Pronoms" variant="outlined" fullWidth style={{ fontFamily: 'AvenirRoman' }} onChange={(e) => setPronom(e.target.value)} />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField label="Téléphone" type="tel" variant="outlined" fullWidth style={{ fontFamily: 'AvenirRoman' }} onChange={(e) => setTelephone(e.target.value)} />
        </Grid> */}
        <Grid item xs={20} sm={16}>
          <FormControl fullWidth>
            <InputLabel id="artist-select-label" style={{ fontFamily: 'AvenirRoman' }}>Choisir un artiste</InputLabel>
            <Select
              labelId="artist-select-label"
              id="artist-select"
              label="Choisir un artiste"
              defaultValue=""
              onChange={handleChoosenArtist}
            >
              {artists.map((artist) => (
                <MenuItem key={artist.id} value={artist.id}>{artist.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            rows={5}
            cols={30}
            value={description}
            placeholder="Expliquez votre projet de tatouage"
            style={{
              width: '100%',
              margin: '8px',
              padding: '10px',
              borderRadius: '4px',
              borderColor: '#c4c4c4',
              borderWidth: '1px',
              fontSize: '16px',
              fontFamily: 'AvenirRoman'
            }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="mes_nouvelles_images"
            multiple
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="mes_nouvelles_images">
            <Button variant="contained" component="span" startIcon={<PhotoCamera />} sx={{ m: 1, fontFamily: 'BasteleurBold', backgroundColor: '#D54800' }}>
              Ajouter des photos
            </Button>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {nouvelleImage.map((imgSrc, index) => (
              <Card key={index} sx={{ maxWidth: 345, m: 1 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={imgSrc.dataUrl}
                    alt={`Uploaded image ${index + 1}`}
                    sx={{ height: 140 }}
                  />
                </CardActionArea>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton color="error" onClick={() => handleDeleteImage(index)}>
                    <DeleteIcon />
                  </IconButton>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, fontFamily: 'BasteleurBold', backgroundColor: '#D54800' }}>
        Envoyer la demande
      </Button>
    </Box>
  );
}
