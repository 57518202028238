import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Axios from 'axios';

export default function SignIn(props) {

    const [erreurPassword, setErreurPassword] = React.useState("");

    const [erreurServer, setErreurServer] = React.useState("");





  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(data.get('password') !== data.get('password-confirm')){
        setErreurPassword("Les mots de passe ne correspondent pas");
    }else {

        Axios.post(`${props.server_url}/inscription`, {
            courriel: data.get('email'),
            motDePasse: data.get('password'),
            nom: data.get('nom'),
            prenom: data.get('prenom')
        }).then((response) => {
            if (response.status === 200) {
                setErreurServer("Votre compte a été créé avec succès");
                // Redirect the user to the login page
                window.location.href = "/";
            }
        }).catch((error) => {
            if (error.status === 400) {
                console.log(error.response.data.message);
                setErreurServer("Une erreur inattendue s'est produite, veuillez revenir plus tard.");
                return;
            }
            if (error.status === 500) {
                setErreurPassword("Ce courriel existe déjà, si vous avez oublié votre mot de passe, too bad!")
                return;
            }
            // console.log(error);
        })



    }


    // console.log(data);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">

        {erreurServer && erreurServer ?  <Typography variant='h2' components='h3'>{erreurServer}</Typography>: null}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Artistes de la Boite à Lune
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField 

                 margin='normal'
                required
                fullWidth
                name='prenom'
                label='Prenom'
                type='Prenom'
                id='prenom'
                autoComplete='prenom'
            />

          <TextField 
                margin="normal"
                required
                fullWidth
                name="nom"
                label="Nom"
                type="Nom"
                id="nom"
                autoComplete="nom"
            />
       
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Courriel"
              name="email"
              autoComplete="Courriel"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
        {erreurPassword && erreurPassword ?  <Typography variant='h5' components='h6'>{erreurPassword}</Typography>: null  }
        <TextField
              margin="normal"
              required
              fullWidth
              name="password-confirm"
              label="Confirmer votre mot de passe"
              type="password"
            //   id="password"
              autoComplete="current-password"
            />
        {erreurPassword && erreurPassword ?  <Typography variant='h5' components='h6'>{erreurPassword}</Typography>: null  }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Inscrivez-vous
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                <Link href="/Login" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    // </ThemeProvider>
  );
}