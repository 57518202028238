import React, { useState, useEffect } from 'react';
import StarsIcon from '@mui/icons-material/Stars';

import { Accordion, AccordionSummary, AccordionDetails, Typography, useMediaQuery } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Axios from 'axios';
import backgroundImage from '../Resources/background_name.png';

export default function FAQ() {
  const [faqs, setFaqs] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)'); 

  const accordionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: '10px',
    marginBottom: '10px',
    overflow: 'hidden',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'BasteleurBold',
    color: '#fff',
  };

  const containerStyle = {
    width: isMobile ? '90%' : '50%', // Adjust width based on screen size
    margin: '0 auto',
    padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
    borderRadius: '10px',
  };

  const contentStyle = {
    fontFamily: 'AvenirRoman',
    color: '#fff',
    fontSize: isMobile ? '0.9rem' : '1.2rem', // Adjust font size for mobile
  };

  const FetchFaq = async () => {
    Axios.get(`${process.env.REACT_APP_URL_API}/getFaq`)
      .then((response) => {
        if (response.status === 200) {
          setFaqs(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchFaq();
  }, []);

  return (
    <div style={containerStyle}>
      <Typography
        variant={isMobile ? 'h4' : 'h2'}
        style={{
          fontFamily: 'BasteleurBold',
          color: '#2f4f4f',
          textAlign: 'center',
          marginBottom: '1rem'
        }}
      >
        FAQ
      </Typography>

      {faqs.map((faq) => (
        <Accordion key={faq.id_faq} style={accordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
            style={headerStyle}
          >
            <Typography variant={isMobile ? 'body1' : 'h5'} style={{ display: 'flex', alignItems: 'center' }}>
              <StarsIcon fontSize={isMobile ? 'small' : 'medium'} /> {faq.question} <StarsIcon fontSize={isMobile ? 'small' : 'medium'} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" style={contentStyle}>{faq.reponse}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}