import { useState } from 'react';
import { 
  Card, 
  CardContent, 
  Grid, 
  Button, 
  Select, 
  MenuItem, 
  IconButton, 
  Typography, 
  Chip 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import Axios from 'axios';

export default function UpdateUserAdmin(props) {
  const [isEditable, setIsEditable] = useState(false);
  // const [nouvelleBio, setNouvelleBio] = useState("");
  const [roles, setRoles] = useState(null);

  const roles_systems = [
    { role: "admin", id: 1 },
    { role: "artiste", id: 2 },
  ];

  // const currentRole = roles_systems.find(role => role.role === props.user_role)?.id;

  const Update_artist = async () => {
    Axios.put(`${process.env.REACT_APP_URL_API}/updateArtisteAdmin`, {
      // bio: nouvelleBio,
      role: roles,
      id: props.artist_id
    })
      .then((response) => {
        if (response.status === 200) {
          props.refresh();
          setIsEditable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer cet artiste?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'Supprimer',
      acceptIcon: 'pi pi-check',
      accept: () => SupprimerUtilisateur(),
      acceptClassName: 'p-button-danger',
    });
  };

  const SupprimerUtilisateur = async () => {
    Axios.delete(`${process.env.REACT_APP_URL_API}/SupprimeArtiste`, {
      data: {
        id: props.artist_id
      }
    })
      .then((response) => {
        if (response.status === 200) {
          props.refresh();
          setIsEditable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{props.nom}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip label={props.user_role} />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <IconButton onClick={() => setIsEditable(!isEditable)}>
              {isEditable ? <SaveIcon style={{ color: 'green' }} /> : <EditIcon style={{ color: 'blue' }} />}
            </IconButton>
            <ConfirmPopup />
            {isEditable && (
              <IconButton onClick={(e) => confirm2(e)}>
                <DeleteForeverIcon style={{ color: 'red' }} />
              </IconButton>
            )}
          </Grid>
          {isEditable && (
            <>
              <Grid item xs={12}>
                <Select
                  label="Choisir un nouveau role pour l'artiste"
                  value={roles}
                  onChange={(e) => setRoles(e.target.value)}
                  fullWidth
                  id="role_choix"
                >
                  {roles_systems.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.role}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button variant="contained" color="primary" onClick={Update_artist}>
                  Mettre à jour
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

