import React from 'react';
import { useState, useEffect } from 'react';
import Axios from 'axios';

import { Card } from 'primereact/card';
import { MenuItem, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Paper, IconButton, Button, TextField, Typography, Box, CssBaseline, Container, CardMedia, CardActionArea } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { Instagram, Facebook, Language, YouTube } from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import Compressor from 'compressorjs';

export default function FicherPersonnelArtiste() {
  const [isEditable, setIsEditable] = useState(false);
  const [MesImages, setMesImages] = useState([]);
  const [nouvelleImage, setNouvelleImage] = useState([]);
  const [maFiche, setMafiche] = useState({});
  const [formData, setFormData] = useState({});
  const [maBio, setMaBio] = useState('');
  const [monAlias, setMonAlias] = useState('');
  const [monPrenom, setMonPrenom] = useState('');
  const [monNom, setMonNom] = useState('');
  const [monCourriel, setMonCourriel] = useState('');
  const [socialLinks, setSocialLinks] = useState([]);
  const [newLink, setNewLink] = useState({ type: '', url: '' });

  const socialTypes = {
    Instagram: <Instagram />,
    Facebook: <Facebook />,
    Weblink: <Language />,
    YouTube: <YouTube />,
    TikTok: <i className='pi pi-tiktok'></i>,
    Autre: <PublicIcon />
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleAddSocialLink = () => {
    if (newLink.type && newLink.url) {
      setSocialLinks([...socialLinks, newLink]);
      setNewLink({ type: '', url: '' });
    }
  };

  const handleRemoveSocialLink = (index) => {
    const updatedLinks = [...socialLinks];
    updatedLinks.splice(index, 1);
    setSocialLinks(updatedLinks);
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL_API}/MybioArtiste`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }, 
        withCredentials: true 
      })
     
      .then((response) => {
        response.data.map((fiche) => {
          setMaBio(fiche.bio);
          setMonAlias(fiche.alias);
          setMonPrenom(fiche.firstname);
          setMonNom(fiche.lastname);
          setMonCourriel(fiche.courriel);

          if (fiche.images_link) {
            const array_images = fiche.images_link.split(',');

            const image_obj = array_images.map((links) => {
              const split_obj = links.split(';');
              return {
                url: split_obj[0],
                nom_fichier: split_obj[1],
                id_image: split_obj[2]
              };

            });
            setMesImages(image_obj);
          }

          if (fiche.liens_sociaux) {
            const socialLinksArray = fiche.liens_sociaux.split(',').map((link) => {
              const [type, url] = link.split(';');
              return { type, url };
            });
            setSocialLinks(socialLinksArray);
          }
          return "";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formData]);

  const handleFileUpload = (event) => {
    const fileArray = Array.from(event.target.files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () =>
          resolve({
            dataUrl: reader.result,
            file: file
          });
      });
    });

    Promise.all(fileArray).then((fileUrls) => {
      setNouvelleImage((prevImages) => [...prevImages, ...fileUrls]);
    });
  };

  const handleDeleteMyPhotosSaved = (nom_fichier, id_image_bd) => {

    Axios.delete(`${process.env.REACT_APP_URL_API}/deleteImages`, {
      headers:{
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      data: {
        nom_fichier: nom_fichier,
        id_image_bd: id_image_bd
      },
      withCredentials: true
    })
      .then((response) => {
        if(response.status === 200){
          refreshPage();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const compressAndUpload = () => {
    const uploadPromises = nouvelleImage.map((image) => {
      return new Promise((resolve, reject) => {
        new Compressor(image.file, {
          quality: 0.6,
          success(result) {
            const formData = new FormData();
            formData.append('image', result, result.name);
            Axios.put(`${process.env.REACT_APP_URL_API}/ImagesUploader`, formData, {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }
            }).then((response) => {
              if (response.status === 200) {
                resolve();
              } else {
                reject(new Error('Failed to upload image'));
              }
            }).catch((error) => {
              reject(error);
            });
          },
          error(err) {
            reject(err);
          }
        });
      });
    });
  
    return Promise.all(uploadPromises);
  };


  // const compressAndUpload = () => {
  //   nouvelleImage.forEach((image) => {
  //     new Compressor(image.file, {
  //       quality: 0.6,
  //       success(result) {
  //         const formData = new FormData();
  //         formData.append('image', result, result.name);
  //         Axios.put('https://pure-brook-81897-af3a7bc2d4f1.herokuapp.com/ImagesUploader', formData, {
  //           withCredentials: true,
  //           headers: {
  //             'Content-Type': 'multipart/form-data'
  //           }
  //         }).then((response) => {
  //           if(response.status === 200){
  //             refreshPage();
  //           }
  //           // console.log(response);
  //         });
  //       },
  //       error(err) {
  //         console.log(err.message);
  //       }
  //     });
  //   });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    Axios.post(`${process.env.REACT_APP_URL_API}/UpdateArtisteFiche`, {
      courriel: monCourriel,
      lastname: monNom,
      firstname: monPrenom,
      bio: maBio,
      alias: monAlias,
      medias_sociaux: socialLinks
    },   
    { withCredentials: true, 
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
     })
    .then((response) => {
      if (response.status === 200) {
        if (nouvelleImage.length > 0) {
          compressAndUpload().then(() => {
            refreshPage();
          }).catch((error) => {
            console.error('Error uploading image:', error);
            refreshPage(); // Refresh the page even if image upload fails
          });
        } else {
          refreshPage();
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteImage = (index) => {
    setNouvelleImage((currentImages) => currentImages.filter((img, idx) => idx !== index));
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper elevation={6} square>
        <Box p={3}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsEditable(!isEditable)} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
          <form noValidate onSubmit={handleSubmit}>
            <Typography component="h1" variant="h5" gutterBottom>
              {monAlias}
            </Typography>
            {isEditable ? (
              <TextField
                autoComplete="alias"
                name="alias"
                variant="outlined"
                fullWidth
                id="alias"
                label="Alias"
                autoFocus
                margin="normal"
                value={monAlias}
                onChange={(e) => setMonAlias(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              <Typography variant="subtitle1">{maFiche.alias}</Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={monPrenom}
                  onChange={(e) => setMonPrenom(e.target.value)}
                  disabled={!isEditable}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  disabled={!isEditable}
                  value={monNom}
                  onChange={(e) => setMonNom(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  name="courriel"
                  autoComplete="email"
                  disabled={!isEditable}
                  value={monCourriel}
                  onChange={(e) => setMonCourriel(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="bio"
                  label="Bio"
                  name="bio"
                  autoComplete="bio"
                  multiline
                  value={maBio}
                  onChange={(e) => setMaBio(e.target.value)}
                  rows={4}
                  disabled={!isEditable}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Médias sociaux</Typography>
                <List>
                  {socialLinks.map((link, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={link.url} secondary={link.type} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSocialLink(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Type"
                  fullWidth
                  value={newLink.type}
                  onChange={(e) => setNewLink({ ...newLink, type: e.target.value })}
                >
                  {Object.keys(socialTypes).map((type) => (
                    <MenuItem key={type} value={type}>
                      {socialTypes[type]} {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="URL"
                  fullWidth
                  value={newLink.url}
                  onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <IconButton color="primary" onClick={handleAddSocialLink}>
                  <AddCircleIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="mes_nouvelles_images"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="mes_nouvelles_images">
                  <Button variant="contained" component="span" disabled={!isEditable} startIcon={<PhotoCamera />}>
                    Rajouter des photos
                  </Button>
                </label>
              </Grid>
            </Grid>
            {isEditable ? (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {nouvelleImage.map((imgSrc, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={imgSrc.dataUrl}
                          alt={`Uploaded image ${index + 1}`}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                          <IconButton color="error" onClick={() => handleDeleteImage(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isEditable}
              >
                Update
              </Button>
            </Box>
          </form>
          {MesImages && MesImages.length > 0 ? (
            <>
              <Typography variant='h6'>
                Mes photos
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {MesImages.map((imgSrc, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={imgSrc.url}
                          // alt={`Uploaded image ${index + 1}`}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                          <IconButton>
                            <DeleteIcon onClick={() => handleDeleteMyPhotosSaved(imgSrc.nom_fichier, imgSrc.id_image)} />
                          </IconButton>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : null}
        </Box>
      </Paper>
    </Container>
  );
}
