import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import Axios from 'axios';

export default function CustomNavElement(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [artistes, setArtistes] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Mes_artistes = () => {
    Axios.get(`${process.env.REACT_APP_URL_API}/fetchArtiste`)
      .then((response) => {
        setArtistes(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.hasMore) {
      Mes_artistes();
    }
  }, [props.hasMore]);

  return (
    <div>
      <Button
        variant="text"
        style={{
          borderTop: 'none',
          borderBottom: 'none',
          color: '#093f37',
          margin: '0px 10px'
        }}
        onClick={props.hasMore ? handleClick : () => window.location.href = props.path}
      >
        {props.nom}
      </Button>

      {props.hasMore && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {artistes.map((artiste, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                window.location.href = `/Artiste/${artiste.id}`;
                handleClose();
              }}
              style={{ color: '#093f37' }}
            >
              {artiste.alias}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
