import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import { Typography, Box, Paper, Grid, Button, Container, Divider } from '@mui/material';
import SocialMediaBadge from './SocialMediaBadge';
import BackgrndImageNom from '../Resources/background_name.png';

export default function Artistes(props) {
  const { id } = useParams();
  const [mesImages, setMesImages] = useState([]);
  const [maBio, setMaBio] = useState('');
  const [monNom, setMonNom] = useState('');
  const [mesSocialMedia, setMesSocialMedia] = useState([]);

  const carouselItemStyle = {
    height: 'auto',
    maxWidth: '100%',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const handleNewImages = async (newPhotos) => {
    setMesImages((prevImages) => [...new Set([...prevImages, ...newPhotos])]);
  };

  // const handleSocialMedia = async (newSocial) => {
  //   setMesSocialMedia((previousMedia) => [...new Set([...previousMedia, ...newSocial])]);
  // };

  const caroussel_template = (item) => {
    return (
      <div style={carouselItemStyle} className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <img src={item} alt="Artwork" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
  };

  useEffect(() => {
    if(id) {
      Axios.get(`${process.env.REACT_APP_URL_API}/fetchBioArtiste/${id}`)
        .then((response) => {
          response.data.forEach(async (informations) => {
            setMaBio(informations.bio);
            setMonNom(informations.alias);

            if (informations.photos) {
              const mes_photos_links = Object.values(informations.photos).map((photo) => {
                const clean_photos = photo.split(';')[0].trim();
                return clean_photos;
              });

              await handleNewImages(mes_photos_links);
            }

            if (informations.medias_sociaux) {
              const mes_medias = informations.medias_sociaux.map((items) => {
                return {
                  url_media: items.split(';')[0].trim(),
                  type_media: items.split(';')[1].trim(),
                };
              });

              setMesSocialMedia(mes_medias);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  return (
    <Container maxWidth="xl" sx={{ padding: '2rem 0', marginTop: '5rem',  borderRadius: '16px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', 
      backdropFilter: 'blur(3px)', 
      WebkitBackdropFilter: 'blur(3px)', 
      border: '1px solid rgba(255, 255, 255, 0.3)', 
       }}>
      <Box className="card" sx={{ mb: 4 }}>
        <Carousel
          value={mesImages}
          itemTemplate={caroussel_template}
          numVisible={2}
          numScroll={2}
          responsiveOptions={responsiveOptions}
          autoplayInterval={4000}
          style={{ borderRadius: '8px', overflow: 'hidden' }}
          header={<Typography variant="h5" sx={{ mb: 2 }}></Typography>}
        />
      </Box>
      <Box
  sx={{
    backgroundImage: `url(${BackgrndImageNom})`,
    width: '100%',
    height: 'auto', 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    mb: 2,
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontFamily: 'BasteleurBold',
      color: '#093f37',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal', 
      wordWrap: 'break-word',
      maxWidth: '100%',
    }}
  >
    {monNom}
  </Typography>
</Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Divider sx={{ margin: '1rem 0' }} />
          <SocialMediaBadge socialMedia={mesSocialMedia} />
          <Box sx={{ margin: '20px 0', display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          minHeight: 'auto', // Remove fixed height to allow dynamic resizing
          maxWidth: '100%', // Ensure it does not overflow its container
          width: 'auto', // Allow width to be determined by content
          fontFamily: 'AvenirRoman', // Fallback fonts
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          backgroundColor: 'transparent',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          textAlign: 'justify', 
        }}
      >
        <Typography
          variant="body1"
          sx={{ width: '100%', fontSize: '1.6rem', lineHeight: '1.8rem', color: '#2f4f4f', textAlign: 'justify' }}
        >
          {maBio || 'Aucune bio disponible'}
        </Typography>
      </Paper>
    </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#D54800',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              '&:hover': {
                backgroundColor: '#d45a51',
              },
            }}
            onClick={() => window.location.href = `/form-demandes`}
          >
            Contacte moi
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
