// import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Component/Navbar';
import Accueil from './Component/Accueil';
import FormDemandes from './Component/FormDemandes';
import Soins from './Component/Soins';
import SignIn from './Component/Signin';
import Login from './Component/Login';
import  Axios  from 'axios';
import Artistes from './Component/Artistes';
import FAQ from './Component/FAQ';
import FormulaireAjoutArtiste from './Component/FormulaireAjoutArtiste';
import FicherPersonnelArtiste from './Component/FicherPersonnelArtiste';
import Footer from './Component/Footer';
import ConfirmationDemandeProjet from './Component/ConfirmationDemandeProjet';
import FormPhotoAdmin from './Component/FormPhotoAdmin';

function App() {
  const SERVER_URL = process.env.REACT_APP_URL_API;
  const [isLogged, setIsLogged] = useState(false); 
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token'); 

    if (token) {
      Axios.get(`${process.env.REACT_APP_URL_API}/login`, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      })
        .then(response => {
          if (response.status === 200) {
            setIsLogged(true);
            setIsAdmin(response.data.permission);
          } else {
            setIsLogged(false);
            setIsAdmin(false);
          }
        })
        .catch(error => {
          console.log("Error checking login status:", error);
          setIsLogged(false);
          setIsAdmin(false);
        });
    } else {
      setIsLogged(false);
      setIsAdmin(false);
    }
  }, []);

  return (
    <div
    style={{minHeight: '100vh'}}
    className='app'    
    >


      

      <Navbar 
      isConnected={isLogged}
      server_url={SERVER_URL}
      isAdmin={isAdmin}

      />

      <BrowserRouter>
        <Routes>
              <Route path="/" element={<Accueil isConnected={isLogged} />} />
              <Route path="/soins" element={<Soins isConnected={isLogged} server_url = {SERVER_URL} />} />
              <Route path="/form-demandes" element={<FormDemandes isConnected={isLogged} server_url = {SERVER_URL}/>} />
              <Route path='/Artiste/:id' element={<Artistes isConnected ={isLogged} server_url = {SERVER_URL}/>} />
              <Route path='/faq' element={<FAQ />} />
              <Route path="/login" element={<Login server_url={SERVER_URL} />} />
             
            <Route path='/ConfirmationDemandeProjet' element={<ConfirmationDemandeProjet isConnected={isLogged} server_url={SERVER_URL}/>} />

            <Route path="/signin" element={<SignIn server_url={SERVER_URL} />} />

              {isLogged && isAdmin ? (

              <> 
              <Route path='/AjoutArtiste' element={<FormulaireAjoutArtiste isConnected={isLogged} server_url={SERVER_URL} />} />
                <Route path="/signin" element={<SignIn server_url={SERVER_URL} />} />
                <Route path='/Profil/Admin' element={<FormPhotoAdmin isConnected={isLogged} server_url={SERVER_URL}/>} />

              </>
              
              ) : null}


              {isLogged && !isAdmin ? (
                <>
                <Route path='/Profil' element={<FicherPersonnelArtiste isConnected={isLogged} server_url={SERVER_URL}/>} />
                </>

              ) : null}

        </Routes>
      </BrowserRouter>


      <Footer />


     
    </div>
  );
}

export default App;

