import React from 'react';
import Axios from 'axios';
import { Button, Box, Typography, Container } from '@mui/material';
import backgroundImage from '../Resources/paper_faq.png';

export default function Soins() {
  const Fetchpdf = async () => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_URL_API}/DownloadPdfSoins`, {
          responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'soins.pdf');

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <Container
      sx={{
        padding: '40px 20px',
        borderRadius: '8px',
        boxShadow: 1,
        mt: 4,

        backdropFilter: 'blur(3px)', // Frosted glass effect
        WebkitBackdropFilter: 'blur(3px)', // Frosted glass effect for Safari
        // background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{ color: '#2f4f4f', fontFamily: 'BasteleurBold', textAlign: 'center' }}
      >
        SOINS APRÈS TATOUAGE
      </Typography>
      <Box textAlign="center" mb={4}>
        <Button
          onClick={Fetchpdf}
          sx={{
            backgroundColor: '#d4a5a5',
            backgroundImage: `url(${backgroundImage})`,
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            padding: '10px 20px',
            cursor: 'pointer',
            fontFamily: 'BasteleurBold',
            '&:hover': {
              backgroundColor: '#b48383',
            },
          }}
        >
          TÉLÉCHARGER PDF
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ color: '#2f4f4f', fontFamily: 'AvenirRoman', lineHeight: 1.6}}
      >
        <Typography variant="h5" sx={{ mb: 4 }}>
          Félicitations pour votre nouveau tatouage! Pour qu'il reste beau et éclatant, il est essentiel de bien en
          prendre soin. Voici quelques conseils importants :
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Soins Immédiats :
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Pansement :</strong> Laissez le pansement en place pendant 2 à 4 heures après la séance.<br />
          <strong>Lavez vos mains :</strong> Avant de toucher votre tatouage, lavez-vous les mains avec du savon
          antibactérien.<br />
          <strong>Nettoyez le tatouage :</strong> Utilisez de l'eau tiède et un savon doux sans parfum. Rincez bien et
          séchez délicatement.
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Soins Généraux :
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Crème hydratante :</strong> Appliquez une crème spécialement conçue pour les tatouages.<br />
          <strong>Évitez le soleil :</strong> Protégez votre tatouage des rayons UV avec un écran solaire (SPF 30 ou
          plus) après guérison complète.<br />
          <strong>Ne grattez pas :</strong> Ne grattez ni ne frottez votre tatouage pour éviter les cicatrices.<br />
          <strong>Vêtements amples :</strong> Portez des vêtements amples pour ne pas irriter votre peau.
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          À Éviter :
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Bains et piscines :</strong> Évitez de submerger votre tatouage dans l'eau pendant deux semaines.<br />
          <strong>Exercice intense :</strong> Limitez les activités physiques intenses pendant les premiers jours.
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Surveillance :
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Signes d'infection :</strong> Surveillez les signes d'infection comme rougeur excessive, gonflement, ou
          douleur persistante. Consultez un professionnel de la santé si nécessaire.<br />
          La guérison complète prend généralement de 2 à 4 semaines, alors soyez patient!
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Pour des informations détaillées sur les soins post-tatouage, téléchargez le PDF complet en cliquant sur le
          bouton ci-dessus.
        </Typography>
      </Box>
    </Container>
  );
}
