import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Compressor from 'compressorjs';
import { Button, Grid, Card, CardActionArea, CardMedia, CardContent, IconButton, Box, Typography, TextField, CardActions } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormPhotoAdmin() {
  const [mesImages, setMesImages] = useState([]);
  const [nouvelleImage, setNouvelleImage] = useState([]);
  const [isEditable, setIsEditable] = useState(true); // Assuming editable state

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL_API}/getImagesAdmin`,
       { 
        headers:{
      Authorization: `Bearer ${localStorage.getItem('token')}` }
       , withCredentials: true })

      .then((response) => {
        response.data.message.forEach((fiche) => {

          if (fiche.images_link) {
            const array_images = fiche.images_link.split(',');

            const image_obj = array_images.map((links) => {
              const split_obj = links.split(';');
              return {
                url: split_obj[0],
                nom_fichier: split_obj[1],
                id_image: fiche.id
              };
            });
            // setMesImages(image_obj);
            newImages(image_obj);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const newImages = (img) => {
    setMesImages((prevImages) => {
        const seenUrls = new Set(prevImages.map((image) => image.url));
        const filteredImages = img.filter((image) => !seenUrls.has(image.url));
        return [...prevImages, ...filteredImages];
  })
    // setMesImages((prevImages) => [...prevImages, ...img]);
  }

  const refreshPage = () => {
    window.location.reload();
  };

  const handleFileUpload = (event) => {
    const fileArray = Array.from(event.target.files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onload = () =>
            resolve({
              dataUrl: reader.result,
              file: file
            });
        });
      });
  
      Promise.all(fileArray).then((fileUrls) => {
        setNouvelleImage((prevImages) => [...prevImages, ...fileUrls]);
      });
  };

  const handleDeleteMyPhotosSaved = (nom_fichier, id_image_bd) => {

    Axios.delete(`${process.env.REACT_APP_URL_API}/SupprimerPhotoAdmin`, {
      data: {
        nom_fichier: nom_fichier,
        id_image_bd: id_image_bd,
      },
      headers:{
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      withCredentials: true
    })
      .then((response) => {
        if (response.status === 200) {
          refreshPage();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const compressAndUpload = () => {
    nouvelleImage.forEach((image) => {
      new Compressor(image.file, {
        quality: 0.6,
        success(result) {
          const formData = new FormData();
          formData.append('image', result, result.name);
          Axios.put(`${process.env.REACT_APP_URL_API}/AjouterPhotoAdmin`, formData, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            if(response.status === 200){
              refreshPage();
            }
          });
        },
        error(err) {
          console.log(err.message);
        }
      });
    });
  };

  const handleDeleteImage = (index) => {
    setNouvelleImage((currentImages) => currentImages.filter((img, idx) => idx !== index));
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Gestion des Photos
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
          Ajoutez, supprimez les photos qui sont dans le carrousel acceuil
        </Typography>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="mes_nouvelles_images"
              multiple
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="mes_nouvelles_images">
              <Button variant="contained" component="span" disabled={!isEditable} startIcon={<PhotoCamera />} style={{backgroundColor: '#e16a5e'}}>
                Rajouter des photos
              </Button>
            </label>
          </Grid>

          {isEditable && nouvelleImage.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6">Nouvelles Images</Typography>
              <Grid container spacing={2}>
                {nouvelleImage.map((imgSrc, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={imgSrc.dataUrl}
                          alt={`Uploaded image ${index + 1}`}
                        />
                        <CardContent>
                          <Typography variant="body2" color="textSecondary" component="p">
                            New Image {index + 1}
                          </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                          <IconButton color="error" onClick={() => handleDeleteImage(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {mesImages && mesImages.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6">Mes Photos</Typography>
              <Grid container spacing={2}>
                {mesImages.map((imgSrc, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={imgSrc.url}
                          alt={`Saved image ${index + 1}`}
                        />
                        <CardContent>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {imgSrc.nom_fichier}
                          </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                          <IconButton color="error" onClick={() => handleDeleteMyPhotosSaved(imgSrc.nom_fichier, imgSrc.id_image)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" variant='contained' style={{backgroundColor: '#e16a5e'}} onClick={compressAndUpload}>
            Mettre à jour
        </Button>
      </CardActions>
    </Card>
  );
}
