import React, { useInsertionEffect } from 'react'
import {useState, useEffect } from 'react';
import Axios from 'axios';

import { Card } from 'primereact/card';
import { FloatLabel } from 'primereact/floatlabel';
import { InputTextarea } from "primereact/inputtextarea";
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import UpdateUserAdmin from './UpdateUserAdmin';
import { Typography } from '@mui/material';
// import DropDownPermission from './DropDownPermission';
        

export default function FormulaireAjoutArtiste(props) {


    const [NosArtistes, setNosArtistes] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const Retourne_Mes_artistes = () => {
        Axios.get(`${props.server_url}/fetchArtiste`)
            .then((response) => {
                setNosArtistes(response.data[0]);
            })
            .catch((error) => {
                console.log(error);
            })
    }


   

    useEffect(() => {
        Retourne_Mes_artistes();
    }, [refreshKey])

  return (
    <div>


        {NosArtistes.map((artiste, index) => {
            return (
                <div key={index} > 
                <UpdateUserAdmin 
                bio={artiste.bio}
                nom={artiste.nom}
                user_role={artiste.role}
                refresh={() => setRefreshKey(oldKey => oldKey + 1)}
                server_url={props.server_url}
                artist_id = {artiste.id}
                />
                </div>
            )
        })}




    </div>
  )
}
