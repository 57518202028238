import React, { useState, useEffect } from 'react';
import { AppBar, Container, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import Axios from 'axios';
import CustomNavElement from './CustomNavElement';
import menu_logo from '../Resources/logo.png';

const Navbar = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [artistes, setArtistes] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    console.log("navbar " + props.isConnected)
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [props.isConnected]);

  const handleLogout = async () => {
    try {
      await Axios.get(`${props.server_url}/logout`); 
      localStorage.removeItem('token'); 
  
      console.log("Logged out successfully");
      window.location.href = "/"; 
    } catch (error) {
      console.log("Error logging out:", error);
    }
  };

  const routes_custom = [
    { name: 'Le studio', path: '/', hasMore: false },
    { name: 'Nos Artistes', path: '/Artiste', hasMore: true },
    { name: 'Contact', path: '/form-demandes', hasMore: false },
    { name: 'Soins', path: '/soins', hasMore: false },
    { name: 'FAQ', path: '/faq', hasMore: false },
  ];

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);

    if (open) {
      Axios.get(`${process.env.REACT_APP_URL_API}/fetchArtiste`)
        .then((response) => {
          setArtistes(response.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const renderDrawer = () => (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box sx={{ width: 250, overflow: 'auto', backgroundColor: '#F9F6EE', height: '100%' }}>
        <List>
          {routes_custom.map((route) => (
            <React.Fragment key={route.name}>
              <ListItem button onClick={() => { window.location.href = route.path; setDrawerOpen(false); }}>
                <ListItemText primary={route.name} primaryTypographyProps={{ style: { color: '#093f37' } }} />
              </ListItem>
              {route.name === 'Nos Artistes' && artistes.map((artiste, index) => (
                <ListItem 
                  button 
                  key={index} 
                  onClick={() => { 
                    window.location.href = `/Artiste/${artiste.id}`;
                    setDrawerOpen(false);
                  }} 
                  sx={{ pl: 4 }} // Indentation for better readability
                >
                  <ListItemText primary={artiste.alias} primaryTypographyProps={{ style: { color: '#e16a5e' } }} />
                </ListItem>
              ))}
            </React.Fragment>
          ))}
   {props.isConnected ? (
  props.isAdmin ? (
    <>
      <ListItem button onClick={() => { window.location.href = '/AjoutArtiste'; setDrawerOpen(false); }}>
        <ListItemText primary="Gérer les artistes" primaryTypographyProps={{ style: { color: '#093f37' } }} />
      </ListItem>
      <ListItem button onClick={() => { window.location.href = '/signin'; setDrawerOpen(false); }}>
        <ListItemText primary="Ajouter un artiste" primaryTypographyProps={{ style: { color: '#093f37' } }} />
      </ListItem>
      <ListItem button onClick={() => { window.location.href = '/Profil/Admin'; setDrawerOpen(false); }}>
        <ListItemText primary="Images admin" primaryTypographyProps={{ style: { color: '#093f37' } }} />
      </ListItem>
    </>
  ) : (
    <>
      <ListItem button onClick={() => { window.location.href = '/Profil'; setDrawerOpen(false); }}>
        <ListItemText primary="Modifier mon profil" primaryTypographyProps={{ style: { color: '#093f37' } }} />
      </ListItem>
      {/* <ListItem button onClick={() => { window.location.href = '/MesRendezVous'; setDrawerOpen(false); }}>
        <ListItemText primary="Mes rendez-vous" primaryTypographyProps={{ style: { color: '#093f37' } }} />
      </ListItem> */}
    </>
  )
) : null}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'rgba(249, 246, 238, 0.5)', boxShadow: 'none',  backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)',  }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: { md: 'none' } }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <img
              src={menu_logo}
              alt="menu_logo"
              style={{ cursor: 'pointer', width: '80px', marginTop: '5px' }}
              onClick={() => window.location.href = '/'}
            />
          </Box>
          <Box>
            {!props.isConnected ? (
              <IconButton color="inherit" onClick={() => window.location.href = "/login"}>
                <ModeNightIcon fontSize='large' />
              </IconButton>
            ) : (
              <IconButton color="inherit" onClick={handleLogout}>
                <BedtimeOffIcon fontSize='large' />
              </IconButton>
            )}
          </Box>
        </Toolbar>
        <Divider sx={{ borderColor: '#093f37', marginY: 1 }} />
        <Toolbar disableGutters sx={{ justifyContent: isMobile ? 'center' : 'flex-start', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
          {routes_custom.map((route) => (
            <CustomNavElement
              key={route.name}
              path={route.path}
              nom={route.name}
              hasMore={route.hasMore}
              server_url={props.server_url}
            />
          ))}
 {props.isConnected ? (
  props.isAdmin ? (
    <>
      <CustomNavElement path={'/AjoutArtiste'} nom={'Gérer les artistes'} hasMore={false} />
      <CustomNavElement path={'/signin'} nom={'Ajouter un artiste'} hasMore={false} />
      <CustomNavElement path={'/Profil/Admin'} nom={'Images admin'} hasMore={false} />
    </>
  ) : (
    <>
      <CustomNavElement path={'/Profil'} nom={'Modifier mon profil'} hasMore={false} />
      {/* <CustomNavElement path={'/MesRendezVous'} nom={'Mes rendez-vous'} hasMore={false} /> */}
    </>
  )
) : null}
        </Toolbar>
      </Container>
      {renderDrawer()}
    </AppBar>
  );
};

export default Navbar;
