import { Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ConfirmationDemandeProjet() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const valid = query.get('isValid') === 'true';
  
 
    return (
        <div style={{ textAlign: 'center', margin: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h1 style={{ color: '#4CAF50' }}>Confirmation de projet</h1>
        {valid ? (
          <div style={{ border: '2px solid #4CAF50', padding: '20px', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
            <p style={{ fontSize: '18px', color: '#555' }}>
              Votre demande a été envoyée avec succès. Nous vous recontacterons dès que possible.
            </p>
          </div>
        ) : (
          <div style={{ border: '2px solid #ff3333', padding: '20px', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
          <p style={{ fontSize: '18px', color: '#555' }}>
            Uh-oh, il y a eu une erreur dans l'envoi de votre demande. Veuillez réessayer plus tard. Si l'erreur persiste, 
            veuillez nous contacter directement à <a href="mailto:info@laboitealune.com" style={{ color: '#4CAF50' }}> </a>
          </p>
        </div>
        )}
      </div>
    );
  }
  