import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Divider } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#093f37', 
        color: '#ffffff', 
        padding: '2rem 0',
        mt: '2rem',
        borderTop: '1px solid #ffffff',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, fontFamily:'BasteleurBold' }}>
               La Boite À Lune
              </Typography>
              <Typography variant="body2" sx={{fontFamily: 'BasteleurBold'}}>
                Art & Tattoo
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', fontFamily:'BasteleurBold' }}>
              Liens rapide
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Link href="/" variant="body2" sx={{ color: '#ffffff', mb: 1 }}>
                Le studio
              </Link>
              <Link href="/form-demandes" variant="body2" sx={{ color: '#ffffff', mb: 1 }}>
                Contacte-nous
              </Link>
              <Link href="/soins" variant="body2" sx={{ color: '#ffffff', mb: 1 }}>
                Soins
              </Link>
              <Link href="/faq" variant="body2" sx={{ color: '#ffffff', mb: 1 }}>
                FAQ
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', fontFamily:'BasteleurBold' }}>
              Suis-nous sur nos réseaux sociaux
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center',  }}>
              <IconButton color="inherit" href="https://www.facebook.com/p/La-Bo%C3%AEte-%C3%A0-Lune-100093210440967/" target='_blank' aria-label="Facebook">
                <Facebook />
              </IconButton>
              <IconButton color="inherit" href="https://www.instagram.com/la.boite.a.lune/" target='_blank' aria-label="Instagram">
                <Instagram />
              </IconButton>
            </Box>
          </Grid>
        </Grid>


        <Divider sx={{ my: 3, borderColor: '#ffffff' }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">
            Contacte-nous:  <a href="mailto:laboitealune1@gmail.com" style={{ color: '#ffffff', fontFamily:'BasteleurBold' }}>laboitealune1@gmail.com</a>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            &copy; {new Date().getFullYear()} La boite à lune. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
